import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';

import {Observable} from 'rxjs';

import {AuthService} from '../../_services/auth.service';
@Injectable({

  providedIn: 'root'

})

export class AuthGuard implements CanActivate {



  constructor(public authService: AuthService,

              public router: Router) {}

  canActivate(

    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {



    if (!this.authService.afAuth){

      this.router.navigate(['login']);

      return false;

    }

    return true;

  }

}
