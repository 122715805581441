import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../_services/auth.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

var usuario: string ;

// @ts-ignore
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService]
})

export class LoginComponent implements OnInit {

  loginForm = new FormGroup ({
  email: new FormControl(''),
  password: new FormControl(''), });
  // @ts-ignore
  private nuevousuario: any;
  constructor(private authSvc: AuthService, private router: Router) {}
  ngOnInit(): void {}

  // tslint:disable-next-line:typedef
  async onLogin(){
    // console.log( this.loginForm.value); //aqui verificamos errores en la consola
     const {email, password} = this.loginForm.value;

     try {
    const user = await this.authSvc.login(email, password);
    if (user){
      this.nuevousuario = email.split('@',1);
      // redireccionar metodo
      await this.router.navigate(['/escaner']);
      // alert("Bienvenido" + " \n " + usuario)
      await Swal.fire({
        title: 'Bienvenido',
        text: (`${this.nuevousuario}`),
        showCloseButton: false,
        showConfirmButton: false,
        backdrop: true,
        timer: 5000,
        timerProgressBar: true,
      });

    }
    else {
      // alert("Usuario o contraseña equivocado");
      await Swal.fire({
        icon: 'error',
        title: 'Usuario o contraseña equivocado',
        confirmButtonColor: '#b50811',
        confirmButtonText: 'Aceptar',
        backdrop: true,
      });
    }
    }
    catch (error) {
      console.log(error);
    }
  }
}
